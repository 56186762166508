<template>
  <div class="preloader">
    <svg
      version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
      width="100px"
      height="100px"
      class="fill-current text-primary-1"
    >
      <circle class="stroke-current" fill="none" stroke-width="4" stroke-miterlimit="10" cx="50" cy="50" r="48"></circle>
      <line class="stroke-current" fill="none" stroke-linecap="round" stroke-width="4" stroke-miterlimit="10" x1="50" y1="50"
            x2="85" y2="50.5">
        <animateTransform
          attributeName="transform" dur="2s" type="rotate" from="0 50 50" to="360 50 50"
          repeatCount="indefinite"></animateTransform>
      </line>
      <line class="stroke-current" fill="none" stroke-linecap="round" stroke-width="4" stroke-miterlimit="10" x1="50" y1="50"
            x2="49.5" y2="74">
        <animateTransform attributeName="transform" dur="15s" type="rotate" from="0 50 50" to="360 50 50"
                          repeatCount="indefinite"></animateTransform>
      </line>
    </svg>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
    setup() {
    },
  });
</script>

<style lang="scss" scoped>
  .preloader {
    &.margin {
      width: 100%;

      svg {
        margin: 0 auto;
      }
    }
  }
</style>
