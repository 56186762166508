<template>
  <div class='base-checkbox' :class='wrapSelectors'>
    <input
      type='checkbox'
      :id='`${getUid}-${id}`'
      class='base-checkbox-input'
      v-bind='$attrs'
      :checked='modelValue'
      @change='updateValue'
    />

    <span class='base-checkbox-mark'>
      <slot name='icon' :value="modelValue">
        <EyeOffIcon v-if='modelValue' class="w-4 h-4" />
        <EyeIcon v-else class="w-4 h-4" />
      </slot>
    </span>

    <label
      v-if="label"
      :for='`${getUid}-${id}`'
      class='base-checkbox-label'
      :class='labelSelectors'
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    id: null,
    label: String,
    wrapSelectors: null,
    labelSelectors: null,
    modelValue: null,
  },
  inheritAttrs: false,
  computed: {
    getUid() {
      return Math.random();
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style lang='scss' scoped>
.base-checkbox {
  position: relative;
  width: 20px;
  height: 20px;

  &:hover,
  &:focus {

  }

  &-input {
    display: none;

    &:checked {
      & ~ .base-checkbox-mark {
      }

      & ~ .base-checkbox-label {
      }
    }
  }

  &-mark {
    position: absolute;
    left: 50%;
    width: 16px;
    height: 16px;
    color: #fff;
    transform: translateX(-50%);

    svg {
      width: 100%;
      height: 100%;
      stroke: currentColor;
    }
  }

  &-label {
    z-index: 1;
    position: relative;
    padding-left: 24px;
    color: #000;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
