<template>
  <div
    v-if="lastPage > 1"
    class="pagination intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-6"
  >
    <ul class="pagination">
      <li class="pagination-start">
        <router-link
          :to="{ path: pages[0].url.path, query: null }"
          :class="{'pagination-link--disabled': isFirst}"
        >
          <ChevronsLeftIcon class="w-4 h-4"/>
        </router-link>
      </li>

      <li class="pagination-prev">
        <router-link
          :to="{ path: pages[prev]?.url?.path, query: pages[prev]?.url?.query }"
          :class="{'pagination-link--disabled': isFirst}"
        >
          <ChevronLeftIcon class="w-4 h-4"/>
        </router-link>
      </li>

      <li
        v-for="(page) in getPagesArray()"
        :key="page.number"
        class="pagination__item"
      >
        <router-link
          :to="{ path: page.url.path, query: page.url.query }"
          :class="currentPage === page.number ? `pagination-link--active` : ``"
        >
          {{ page.number }}
        </router-link>
      </li>

      <li>
        <router-link
          :to="{ path: pages[next]?.url?.path, query: pages[next]?.url?.query }"
          :class="{'pagination-link--disabled': isLast}"
        >
          <ChevronRightIcon class="w-4 h-4"/>
        </router-link>
      </li>

      <li class="pagination-end">
        <router-link
          :to="{ path: pages[pages.length - 1].url.path, query: pages[pages.length - 1].url.query }"
          :class="{'pagination-link--disabled': isLast}"
        >
          <ChevronsRightIcon class="w-4 h-4"/>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      currentPage: {
        type: Number,
        required: true,
      },
      lastPage: {
        type: Number,
        required: true,
      },
      range: {
        type: Number,
        default: 10,
      },
    },
    methods: {
      getPagesArray() {
        if (this.currentPage < this.range) {
          return this.pages.slice(0, this.range);
        } else {
          return [
            ...this.pages.slice(this.currentPage - 1 - this.range, this.currentPage - 1),
            ...this.pages.slice(this.currentPage - 1, this.currentPage + this.range),
          ];
        }
      },
    },
    computed: {
      pages() {
        if (this.lastPage <= 1) return [];
        let pagesArray = [];

        for (let page = 1; page <= this.lastPage; page++) {
          pagesArray.push({
            number: page,
            url: {
              path: this.$route.path,
              query: { ...this.$route.query, page: page },
            },
          });
        }
        return pagesArray;
      },
      isFirst() {
        return this.currentPage === 1;
      },
      isLast() {
        return this.currentPage === this.lastPage;
      },
      prev() {
        return this.currentPage <= 1 ? 0 : this.currentPage - 2;
      },
      next() {
        return this.currentPage >= this.lastPage ? this.currentPage - 1 : this.currentPage;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pagination {
    li {
      width: 40px;
      height: 40px;
      @apply mr-2 last:mr-0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #000;
        line-height: 1;
        border-radius: 50%;
        transition: all .3s ease;
        @apply bg-gray-300;

        &.pagination-link--active {
          pointer-events: none;
          color: #fff;
          @apply bg-primary-1;
        }

        &.pagination-link--disabled {
          pointer-events: none;
          opacity: 0.7;
          @apply bg-gray-300;
        }

        &:hover,
        &:focus,
        &:active {
          @apply bg-gray-400;
        }
      }
    }
  }
</style>
