<template>
  <div id="header-footer-modal-preview" class="modal-create" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            {{ title }}
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
          <div class="col-span-12 sm:col-span-6" v-for="form in modalData">
            <label class="form-label" :for="form.dataField">{{ form.label }}</label>
            <select v-if="form.select" v-model="form.value" :id="form.dataField" class="form-select">
              <option v-for="opt in form.select" :value="opt.value">{{opt.label}}</option>
            </select>
            <input v-else :type="form.type || 'text'" class="form-control" v-model="form.value" :placeholder="form.label"/>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer text-right">
          <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1"
                  @click.prevent="closeModal">
            Cancel
          </button>
          <button type="button" class="btn btn-primary w-20" @click.prevent="accept">
            Send
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
  <!-- END: Modal Content -->
</template>

<script>
export default {
  name: "CreateUpdateModal",
  props: {
    modalData: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Создать"
    }
  },
  methods: {
    closeModal() {
      this.$emit('modalClose')
    },
    accept() {
      this.$emit('modalAccepted', this.modalData)
    }
  }
}
</script>

<style scoped lang="scss">
.modal-create {

}
::v-deep{
  .modal-dialog {
    width: 600px;
  }
}
</style>
