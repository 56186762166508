<template>
  <transition name="fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon
              class="w-16 h-16 text-theme-6 mx-auto mt-3"
            />
            <div class="text-3xl mt-5">Удалить {{modalData.title}}?</div>
            <div class="text-gray-600 mt-2">
              после удаления данные нельзя будет восстановить
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              @click.prevent="closeModal"
            >
              Отмена
            </button>
            <button type="button" class="btn btn-danger w-24" @click="$emit('modalAccepted', true)">
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['isOpen', 'title', 'acceptFunction', 'modalData'],
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('modalClose')
    },
    acceptComplete() {
      this.$emit('modalAccepted');
    }
  },
  destroyed() {
    console.log('modal destroyed')
  }
}
</script>
<style scoped lang="scss">
//.my-modal{
//  position: fixed;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  width: 100%;
//  height: 100%;
//  left: 0;
//  top: 0;
//  background: rgba(#000, .5);
//  z-index: 10000;
//}
::v-deep {
  .modal-dialog {
    width: 400px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
